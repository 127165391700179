// branding configuration
$publication: 'men';
$logo-dimensions: 479, 160;
$primary-color: #F1BB00;
$brand-hue: 47;
$brand-saturation: 100%;

$brand-color: hsl($brand-hue, $brand-saturation, 47%);
$highlight: hsl(37, 100%, 52%);
$hint: hsl($brand-hue, $brand-saturation,  97%);
$lowlight: hsl($brand-hue, $brand-saturation,  7%);
// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($pitch-black, $primary-color);
    }

  }

  .earthquake {
    .social-sites {
      .icon {
        border: 1px solid;
      }
    }
  }

  #signin {

    .icon {
      color: $primary-color;
    }

    .label {
      color: $primary-color;

      span {
        box-shadow: inset 0 0 0 2px $primary-color;
      }
    }

    .logout-wrapper {

      .name {
        background-color: $pitch-black;
        border: 2px solid $primary-color;
        color: $primary-color;
      }

    }

  }

  .gigya-screen {

    .gigya-composite-control-submit .gigya-input-submit {

      color: $pitch-black;

    }
  }

  gigya-login-form-customized .gigya-native-login {

    .gigya-input-submit {

      // sass-lint:disable no-important

      &:hover {

        color: $pitch-black !important;
      }

      // sass-lint:enable no-important

    }

  }

  %hamburger-bar {
    background: $pitch-black;
  }

  .sticky-header {
    .homepage-header {
      .search {
        &:before {
          background: $white;
        }  
      }
    }
  }

  .mod-header {

    &:not([data-expanded]) {
      #hamburger {
        &:before {
          @extend %hamburger-bar;
        }

        &:after {
          @extend %hamburger-bar;
        }

        > span {
          @extend %hamburger-bar;
        }
      }
    }

    >.primary {

      [data-level='1'] {

        >li {

          // scss-lint:disable SelectorDepth, NestingDepth
          >a {

            color: $pitch-black;

            &:hover {
              color: $slate-grey;
            }
          }
          // scss-lint:enable SelectorDepth, NestingDepth
        }

        .has-children {

          // scss-lint:disable NestingDepth, SelectorDepth
          &:after {
            border-color: $pitch-black transparent transparent;
          }

          &:hover {
            >a {
              color: $slate-grey;
            }

            &:after {
              border-color: $slate-grey transparent transparent;
            }
          }
        }
      }
    }
    >.primary.earthquake {
      [data-level='1'] {
        >li {
          // scss-lint:disable SelectorDepth, NestingDepth
          >a {
            color: $white !important; // sass-lint:disable-line no-important
          }
          // scss-lint:enable SelectorDepth, NestingDepth
        }

        .has-children {
          // scss-lint:disable NestingDepth, SelectorDepth
          &:after {
            border-color: $white transparent transparent;
          }
        }
      }
    }

    .search {

      &:before {
        background: $pitch-black;
      }

      .icon {
        color: $pitch-black;
      }

    }

    .earthquake { 
      .search {
        &:before {
          background: $white;
        }
        .icon {
          color: var(--pub-primary-color);
        }
      }
    }
  }

  .with-topbox {
    .mod-header .secondary ul a {
      color: $pitch-black;
    }
  }

  #recommendations-topbox {
    .teaser .headline,
    .advertising-shopwindow-link a {
      color: $pitch-black;
    }
  }

  #breaking-news {
    .header {
      .publication-theme {
        color: $pitch-black !important; // sass-lint:disable-line no-important
      }
    }
  }

  .article-information {
    .publication-theme {
      color: $pitch-black !important; // sass-lint:disable-line no-important
    }
  }

  .gallery,
  .photo-story {
    .article-information {
      .publication-theme {
        color: $pitch-black !important; // sass-lint:disable-line no-important
      }
    }
  }


  .article-type {

    .advertorial,
    .channel-name {
      color: $pitch-black;
    }

  }

  .section-theme-highlight {
    color: $pitch-black;
  }

  .cta-bar-comment-button {
    color: $pitch-black !important; // sass-lint:disable-line no-important
  }

  .cta-bar-svg {
    fill: $pitch-black !important; // sass-lint:disable-line no-important
  }

  .live-event {
    .live-event-entry {
      .sharebar-comments {
        color: $pitch-black !important; // sass-lint:disable-line no-important

        .count-comments {
          background-image: url('/@trinitymirrordigital/article-service/live-event/img/comments.svg');
        }

        .count-num {
          color: $pitch-black !important; // sass-lint:disable-line no-important
        }
      }
    }
  }

  .teaser.teaser-opinion {

    p {

      a {
        color: $pitch-black;
      }

    }

  }

  .channel-whats-on {

    .article-type {

      .channel-name {
        color: $white;
      }

      .seldon-recs {

        section-theme {
          color: $steel-drum;
        }

      }

    }

    .pancake.ensemble {
      // scss-lint:disable SelectorDepth, NestingDepth
      .teaser-highlight em a {
        color: $white;
      }
      // scss-lint:enable SelectorDepth, NestingDepth
    }
  }

  .article-body {

    p a {
      color: $pitch-black;
    }

    .photo-story-embed {

      .publication-theme {
        color: $pitch-black !important; // sass-lint:disable-line no-important
      }

      .photo-story-embed-caption {
        p {
          color: $pitch-black;
        }
      }
    }
  }

  .static-content {

    a {
      color: $pitch-black;
    }
  }

  .live-event-section-view {

    h2 {

      &:hover {
        color: $white;
      }
    }
  }

  .teaser-featured {

    em a {
      color: $pitch-black;
    }
  }

  .pancake.solo {

    &.splash {

      em a {
        color: $pitch-black;
      }
    }

    &.wipeout {

      .inner {
        border-color: $primary-color;
      }
    }
  }

  .pancake.ensemble {

    &.channel-whats-on {

      div {

        &.teaser-highlight em a {
          color: $white;
        }
      }
    }

    div {

      &.teaser-highlight em a {
        color: $pitch-black;
      }
    }
  }

  .hotjobs {

    .job-by-email {
      color: $pitch-black;
    }

    .title {
      color: $pitch-black;

      .icon-rps-jobs {
        color: $pitch-black;
      }
    }

    .poweredby {
      background-image: url('/@trinitymirrordigital/chameleon-partnership-widgets/madgex/img/fish4-logo-pb.png');
      color: $pitch-black;
    }

  }

  .advertise-form {
    .power-logo,
    .submit {
      color: $pitch-black;
    }
  }

  .teaser .live-event-icon:before {
    color: $pitch-black;
  }

  .teaser .live-event-icon:after {
    color: $pitch-black;
  }

  .weather-tile .weather-report-error {
    color: $pitch-black;
  }

  .skinny-signup .skinny-form p,
  .submit-button-skinny-signup,
  .submit-button-skinny-signup:hover,
  .skinny-signup .skinny-form .dog-lovers h1,
  .skinny-signup .skinny-form .dog-lovers p,
  .skinny-signup-slide-in-container .skinny-signup .skinny-form .confirm .show-more {
    color: #141414 !important; // sass-lint:disable-line no-important
  }

  .byline,
  .sharebar {
    .comments-container {
      .sharebar-comments-svg {
        fill: $pitch-black !important; // sass-lint:disable-line no-important
      }
      .count-text,
      .count-num {
        color: $pitch-black !important; // sass-lint:disable-line no-important
      }
    }
  }

  .article-page {
    &.photo-story {
      .sharebar {
        .count-text {
          color: $pitch-black !important; // sass-lint:disable-line no-important
        }
      }
    }
  }
}

